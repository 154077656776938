/* eslint-disable global-require */
import InnerHTML from 'dangerously-set-html-content'
import React from "react";
import PropTypes from "prop-types";
import Layout from "../layout";
import SEO from "../seo";
import { graphql, Link } from "gatsby";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

export const query = graphql`
  query getBlogPost($id: String) {
    blogJson(id: { eq: $id }) {
      content
      title
      tags
    }
  }
`;

const Blog = ({ data, pageContext }) => {
  const { previous, next } = pageContext;
  const blog = data.blogJson;
  const { content, title, tags } = blog;
  const tagMarkup = [];
  tags.forEach((tag) => {
    if (tag) {
      tagMarkup.push(
        <Link
          className="px-3 py-1 border mr-2 inline-block bg-gray-100 hover:bg-gray-200 my-2 cursor-pointer"
          key={tag}
          to="/blog"
          state={{ tag }}
        >
          {tag}
        </Link>
      );
    }
  });
  return (
    <Layout>
      <SEO title={title} />
      <div className="max-w-2xl m-auto p-6">
        <h1 className="text-4xl py-4 border-b">{title}</h1>
        {tagMarkup}
        <InnerHTML className="text-lg my-4 blog" html={content} />
        <div className="flex justify-between">
          {previous && (
            <div className="text-xl text-darkred hover:text-charcoal border-b">
              <Link to={`/blog/${previous}`} className="flex items-center">
                <FaArrowLeft className="mr-2" /> Previous Post
              </Link>
            </div>
          )}
          {next && (
            <div className="ml-auto text-xl text-darkred hover:text-charcoal border-b">
              <Link to={`/blog/${next}`} className="flex items-center">
                Next Post <FaArrowRight className="ml-2" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Blog;
